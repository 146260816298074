<template>
<v-container>
  <v-row justify="end" style="margin-right: 10px;">
    <v-menu min-width="200px" rounded>
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" style="border: 2px solid white;">
          <v-avatar color="primary"> <v-icon>mdi-account-circle</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-card min-width="180">
        <v-card-text>
          <div class="mx-auto text-center ">
            <v-avatar color="primary">
              <span class="white--text text-h5">{{ initials }}</span>
            </v-avatar>
            <h3>
              {{userFullName}}</h3>
            <p class="text-caption mt-1">
              <v-icon>mdi-email</v-icon>
              {{ userEmail }}
            </p>
            <v-divider class="my-3"></v-divider>
            <v-btn rounded variant="text" @click="dialogForPass=!dialogForPass" append-icon="mdi-lock-reset">
              Change Password
            </v-btn>
            <v-divider class="my-3"></v-divider>
            <v-btn rounded variant="text" @click="logout" append-icon="mdi-logout">
              Log out
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-row>
</v-container>
<v-dialog v-model="dialogForPass" max-width="990px" persistent>
  <v-card>
    <v-card-title class="text-size text-center">{{ dialogTitle }}</v-card-title>
    <v-card-text>
      <v-subheader>{{ dialogDescription }}</v-subheader>
      <div class="mt-5">
        <v-text-field auto-grow variant="filled" label="Old password*" v-model="oldPassword" :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" :error-messages="error.oldPassword" @input="remove_error('oldPassword')" @click:append-inner="show = !show"></v-text-field>
        <v-text-field auto-grow variant="filled" label="New password*" v-model="newPassword" :append-inner-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'" :type="showNew ? 'text' : 'password'" :error-messages="error.newPassword" @click:append-inner="showNew = !showNew" @input="remove_error('newPassword')" hint="1.Your password can’t be a commonly used password.  2.Your password can’t be too similar to your other personal information."></v-text-field>
        <v-text-field auto-grow variant="filled" label="New password confirmation*" v-model="confirmPass" :append-inner-icon="showconf ? 'mdi-eye' : 'mdi-eye-off'" :type="showconf ? 'text' : 'password'" @click:append-inner="showconf = !showconf" :error-messages="error.confirmPass" @input="remove_error('confirmPass')"></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click=dialog_close()>Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="validateInput()" :disabled="BtnDisable">Change Password</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <div v-if="error.api" class="mb-10 mt-0 text-center" :style="{ color: 'red' }">{{ error.api }}</div>
    <div id="snackbar" v-if="snackbar">Password changed..</div>
  </v-card>
</v-dialog>
</template>

<script>
import apiUrl from '@/api/allApis';
import '../assets/css/profile.css';
import jwtMixin from '@/mixins/jwt.js'
import logoutMixin from '@/mixins/logout'
import {
  jwtDecode
} from 'jwt-decode';
import vaildationMixin from "../mixins/validation"
export default {
  mixins: [vaildationMixin,jwtMixin,logoutMixin],
  data: () => ({
    BtnDisable: false,
    initials: '',
    show: false,
    showNew: false,
    showconf: false,
    dialogForPass: false,
    dialogTitle: "Password Change",
    dialogDescription: "Please enter your old password, for security’s sake, and then enter your new password twice so we can verify you typed it in correctly.",
    confirmPass: '',
    newPassword: '',
    oldPassword: '',
    error: {
      confirmPass: '',
      newPassword: '',
      oldPassword: '',
      api: ''
    },
    snackbar: false
  }),
  computed: {
    isFormFilled() {
      return (
        (this.error.oldPassword === '' || this.error.oldPassword === undefined) &&
        (this.error.newPassword === '' || this.error.newPassword === undefined) &&
        (this.error.confirmPass === '' || this.error.confirmPass === undefined)
      );
    }
  },
  created() {
    let Token = sessionStorage.getItem('userToken')
    if (Token) {
      this.decodeToken(Token)
    } else {
      this.$router.push({
        name: 'loginPage'
      });
    }
  },
  methods: {
    decodeToken(jwtToken) {
      const decoded = jwtDecode(jwtToken);
      this.id = decoded.request.user_id
      // this.userFirstrName = decoded.request.user_first_name;
      // this.userLastName = decoded.request.user_last_name;
      this.userFullName = decoded.request.user_full_name;
      this.userEmail = decoded.request.user_email;
      this.initials = this.userFullName.charAt(0).toUpperCase()
    },
    remove_error(value) {
      switch (value) {
        case 'oldPassword':
          let oldPassword = this.validate_pass(this.oldPassword, 'Old Password');
          if (!oldPassword.valid) {
            this.error.oldPassword = oldPassword.error;
            break;
          } else {
            this.error.oldPassword = ''
            break;
          }
        case 'newPassword':
          let newPassword = this.validate_pass(this.newPassword, 'New Password');
          if (!newPassword.valid) {
            this.error.newPassword = newPassword.error;
            break;
          } else {
            this.error.newPassword = ''
            break;
          }
        case 'confirmPass':
          let confirmPass = this.validate_pass(this.confirmPass, 'Confirm Password');
          if (!confirmPass.valid) {
            this.error.confirmPass = confirmPass.error;
            break;
          } else {
            this.error.confirmPass = ''
            break;
          }
        default:
          this.error = {
            token: '',
            name: '',
            description: '',
            instruction: '',
            max_users: '',
            purchase_amt: '',
            discount_amt: '',
            credit_amt: '',
            free_credit_expiry: '',
            credit_expiry_duration: '',
            api: ''
          }
      }
    },

    validateInput() {
      const oldPassword = this.valid_inputs(this.oldPassword)
      const newPassword = this.valid_inputs(this.newPassword)
      const confirmPass = this.valid_inputs(this.confirmPass)
      if (!oldPassword.valid) {
        this.error.oldPassword = oldPassword.error
      } else if (!newPassword.valid) {
        this.error.newPassword = newPassword.error
      } else if (!confirmPass.valid) {
        this.error.confirmPass = confirmPass.error
      } else if (this.newPassword !== this.confirmPass) {
        this.error.confirmPass = "New password and confirm password do not match."
      } else {
        if (this.isFormFilled) {
          this.error = {
            oldPassword: '',
            newPassword: '',
            confirmPass: '',
          }
          this.change_password()
        }
      }
    },
    async change_password() {
      this.BtnDisable = true
      try {
        const body = {
          email: this.userEmail,
          sort_id: this.id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        const jsonString = JSON.stringify(body);
        await fetch(apiUrl.changePasswordWithLogin, {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              'Authorization':JSON.parse(this.jwt_token())
            },
            body: jsonString
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.status) {
              this.myFunction();
              this.show = false
              this.showNew = false
              this.showconf = false
            } else {
              this.error.api = data.message ? data.message : data;
            }
          });
      } catch (error) {
        this.error.api = error;
      } finally {
        this.BtnDisable = false
      }
    },
    myFunction() {
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
        this.dialog_close();
      }, 1000);
    },
    dialog_close() {
      this.dialogForPass = !this.dialogForPass;
      this.confirmPass = ''
      this.newPassword = ''
      this.oldPassword = ''
      this.show = false
      this.showNew = false
      this.showconf = false
      this.error = {
        oldPassword: '',
        newPassword: '',
        confirmPass: '',
      }
    }

  }
}
</script>
